<template>
  <div>
    <div id="container"></div>
    <label> {{ debugText }}</label>
    <img id="thumbnail">
  </div>
</template>

<script>
// eslint-disable-next-line
import * as Three from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { VRMLoaderPlugin } from '@pixiv/three-vrm';
import * as ModelTools from '../tools/ModelTools.js'

export default {
  name: "ModelViewer",

  data() {
    return {
      debugText: "",
      isDebugEnabled: false,
      isAutoRotateEnabled: false,
      isVRMEnabled: false
    };
  },
  mounted() {
    this.init()
    this.animate()
  },
  methods: {
    init: function () {
      let container = document.getElementById('container')
      this.camera = new Three.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 0.01, 2000)
      this.camera.position.set(0, 1.5, -4)
      this.camera.lookAt(new Three.Vector3(0, 1, 0))

      this.scene = new Three.Scene()
      this.scene.background = new Three.Color(0xffffff);
      this.scene.fog = new Three.Fog(0xffffff, 1, 2000);

      var light = new Three.AmbientLight(0xaaaaaa);
      this.scene.add(light);


      this.gridHelper = new Three.GridHelper(10, 10);
      this.gridHelper.visible = this.isDebugEnabled
      this.scene.add(this.gridHelper);

      this.axesHelper = new Three.AxesHelper(5);
      this.axesHelper.visible = this.isDebugEnabled
      this.scene.add(this.axesHelper);

      light = new Three.HemisphereLight(0xffffbb, 0x080820, 1);
      this.scene.add(light);

      this.renderer = new Three.WebGLRenderer({ antialias: true })
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      container.appendChild(this.renderer.domElement)

      // camera controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.screenSpacePanning = true;
      this.controls.target.set(0.0, 1.5, 0.0);
      this.controls.update();


      this.model = null
      this.modelBounds = null
      this.modelSkeleton = null

      this.currentModel = null

      this.eyeBone = null
      this.hipsBone = null
      this.rotationValue = -10
    },
    animate: function () {
      requestAnimationFrame(this.animate)
      if (this.model != null) {
        if (this.isAutoRotateEnabled) {
          this.model.rotation.y += 0.01
          if (this.modelBounds != null) {
            this.modelBounds.rotation.y += 0.01
          }
        }
        // //        ModelTools.boneLookAt(this.hipsBone, new Three.Vector3(0, 20 - this.rotationValue, 0), new Three.Vector3(0, this.rotationValue, 0))
        // ModelTools.rotateBone(this.hipsBone, new Three.Vector3(0, Math.PI / 10.0, 0))

        // const leftPoint = this.leftHandBone.localToWorld(new Three.Vector3(0, 0, 0))
        // const rightPoint = this.rightHandBone.localToWorld(new Three.Vector3(0, 0, 0))

        // this.debugText = JSON.stringify(leftPoint) + "   " + JSON.stringify(rightPoint) + ", angle:" +
        //   ModelTools.angle2points(rightPoint.x, rightPoint.z, leftPoint.x, leftPoint.z)



        // const leftPoint = this.leftHandBone.localToWorld(new Three.Vector3(0, 0, 0))
        // const rightPoint = this.rightHandBone.localToWorld(new Three.Vector3(0, 0, 0))

        // const forwardPoint = this.eyeBone.getWorldDirection(new Three.Vector3(0, 0, 0))
        // const forwardAngle = ModelTools.angle2points(0, 0, forwardPoint.x, forwardPoint.z)

        // const angle = ModelTools.angle2points(rightPoint.x, rightPoint.z, leftPoint.x, leftPoint.z)
        // this.debugText =// JSON.stringify(leftPoint) + "   " + JSON.stringify(rightPoint)+ "  " +
        //   "angle: " + angle + "  eyeAngle:   " + Three.MathUtils.radToDeg(this.eyeBone.rotation.y)
        //   + "  hipsAngle:   " + JSON.stringify(forwardPoint) + "  forwardAngle: " + forwardAngle

        if (this.modelSkeleton != null) {
          this.modelSkeleton.rotation.y = this.model.rotation.y
        }
      }
      if (this.scene != null) {
        this.renderer.render(this.scene, this.camera)
        //        console.log("renderer.info.render.faces: " + this.renderer.info.render.triangles)
      }
    },
    fixModelRotation() {
      if (this.model != null) {
        ModelTools.fixModelRotation(this.model)
        ModelTools.applyMatrix(this.model)
        this.updateCamera()
        this.updateBounds()
      }
    },
    fixModelScale() {
      if (this.model != null) {
        ModelTools.fixModelScale(this.model)
        ModelTools.applyMatrix(this.model)
        this.updateCamera()
        this.updateBounds()
      }
    },
    updateModel(gltf) {

      // console.log("updateModel, gltfScene: " + gltfScene + ", gltfScene.userData: " + JSON.stringify(gltfScene.userData) + ", userData: " + JSON.stringify(userData))
      gltf.scene.extensions = []
      if ('gltfExtensions' in gltf.userData && 'VRM' in gltf.userData.gltfExtensions) {
        // gltfScene.extensions["VRM"] = userData.gltfExtensions.VRM
        console.log("gltfScene.extensions: " + Object.keys(gltf.scene.extensions))
        console.log("gltfExtensions: " + Object.keys(gltf.userData.gltfExtensions))
        const vrm = gltf.userData.gltfExtensions.VRM
        ModelTools.fixBonesNames(gltf.scene, gltf.parser.json.nodes, vrm.humanoid?.humanBones || [])
      }
      //      gltfScene.userData.gltfExtensions = userData.gltfExtensions
      //      console.log("updateModel, gltf.scene: " + JSON.stringify(gltf.scene))
      //      console.log("updateModel, gltf.scene: " + gltf.scene + ", gltf.userData: " + JSON.stringify(gltf.userData.gltfExtensions))




      this.hipsBone = ModelTools.findBone(gltf.scene, "hips")
      this.eyeBone = ModelTools.findBone(gltf.scene, "eye")

      this.currentModel = gltf
      ModelTools.fetchModelInfo(this.currentModel.scene)
      ModelTools.getBoneList(this.currentModel.scene)

      this.showCurrentModel()
      this.$emit('onModelAvailable', this.model)


      this.showTexture(gltf.userData?.vrmMeta?.texture?.source?.data)
    },
    updateCamera() {
      const aabb = new Three.Box3().setFromObject(this.model);
      const center = aabb.getCenter(new Three.Vector3());
      //      this.camera.position.set(0, bounds.y / 2, Math.max(bounds.x, bounds.z) / 2 + 3);
      this.camera.lookAt(new Three.Vector3(0, center.y, 0));
      this.camera.position.set(0, center.y, -4)
      this.controls.reset();
      this.controls.target.set(0, center.y, 0);
      this.controls.update();
    },
    updateBounds() {
      if (this.modelBounds != null) {
        this.scene.remove(this.modelBounds)
        this.modelBounds = null
      }

      const aabb = new Three.Box3().setFromObject(this.model);
      const center = aabb.getCenter(new Three.Vector3());
      const bounds = aabb.getSize(new Three.Vector3());
      const geometry = new Three.BoxGeometry(bounds.x, bounds.y, bounds.z)
      const material = new Three.MeshNormalMaterial()

      material.wireframe = true

      this.modelBounds = new Three.Mesh(geometry, material)
      this.modelBounds.position.y = center.y
      this.modelBounds.visible = this.isDebugEnabled
      this.scene.add(this.modelBounds);
    },
    showCurrentModel() {
      let self = this
      if (self.model != null) {
        self.scene.remove(self.model)
      }
      if (self.modelSkeleton != null) {
        self.scene.remove(self.modelSkeleton)
      }

      self.model = null
      self.modelSkeleton = null

      self.model = self.currentModel.scene;
      console.log("self.model.userData =  " + self.model.userData)

      const aabb = new Three.Box3().setFromObject(self.model);
      const bounds = aabb.getSize(new Three.Vector3());

      self.model.position.x = 0
      self.model.position.y = -aabb.min.y
      self.model.position.z = 0

      self.scene.add(self.model);

      console.log("aabb: " + JSON.stringify(aabb))
      console.log("bounds: " + JSON.stringify(bounds))

      console.log(Math.max(bounds.x, bounds.z), bounds.x, bounds.z)

      self.updateBounds()
      self.updateCamera()

      self.modelSkeleton = new Three.SkeletonHelper(self.model);
      self.scene.add(self.modelSkeleton);

      self.modelSkeleton.visible = self.isDebugEnabled

      //      self.fixModelRotation()

    },
    loadFbx(path) {
      let self = this
      const fbxLoader = new FBXLoader();
      fbxLoader.load(
        path,
        function (fbx) {
          console.log(fbx)

          const scene = new Three.Scene()
          scene.add(fbx)

          const exporter = new GLTFExporter();
          exporter.parse(
            scene,
            function (gltfModel) {
              const blob = new Blob([gltfModel], { type: "application/octet-stream" });
              const blobURL = URL.createObjectURL(blob);
              console.log("blobURL", blobURL)
              self.loadGltf(blobURL)
            },
            function (error) {
              console.log('An error happened', error);
            },
            { binary: true }
          );

        }
      )
    },
    loadGltf(path) {
      let self = this
      const loader = new GLTFLoader();
      if (this.isVRMEnabled) {
        loader.register((parser) => {
          return new VRMLoaderPlugin(parser);
        });
      }
      loader.load(
        path,
        function (gltf) {
          //          ModelTools.walk(gltf)
          //          gltf.parser.json.asset.generator = "test"
          //          console.log(JSON.stringify(gltf.parser.json))
          self.updateModel(gltf)
        }
      );
    },
    onFileReceived(file, fileName) {
      console.log("onFileReceived", typeof file + "  " + fileName)
      const blob = new Blob([file]);
      const blobURL = URL.createObjectURL(blob);
      console.log("blobURL", blobURL)
      if (fileName.toLowerCase().indexOf(".fbx") == fileName.length - 4) {
        this.loadFbx(blobURL)
      } else {
        this.loadGltf(blobURL)
      }
    },
    showTexture(image) {
      let thumbnail = document.getElementById('thumbnail')
      if (image) {
        console.log("updateModel 3:", image)

        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('bitmaprenderer');
        context.transferFromImageBitmap(image);
        thumbnail.src = canvas.toDataURL();
      } else {
        thumbnail.src = ""
      }
    }
  },
  watch: {
    isDebugEnabled: function (value) {
      if (this.modelBounds != null) {
        this.modelBounds.visible = value
      }
      if (this.modelSkeleton != null) {
        this.modelSkeleton.visible = value
      }
      if (this.axesHelper != null) {
        this.axesHelper.visible = value
      }
      if (this.gridHelper != null) {
        this.gridHelper.visible = value
      }
    }
  }
}
</script>


<style scoped>
#container {
  height: 512px;
}

#thumbnail {
  width: 100%;
  max-width: 256px;
}
</style>



