import * as Three from 'three'

export function fetchModelInfo(scene) {
    let objects = 0, vertices = 0, triangles = 0;
    for (let i = 0, l = scene.children.length; i < l; i++) {
        const object = scene.children[i];
        object.traverseVisible(function (object) {
            objects++;
            if (object.isMesh) {
                const geometry = object.geometry;
                vertices += geometry.attributes.position.count;
                if (geometry.index !== null) {
                    triangles += geometry.index.count / 3;
                } else {
                    triangles += geometry.attributes.position.count / 3;
                }
            }
        });
    }
    console.log("objects: " + objects + ", vertices: " + vertices + ", triangles: " + triangles)
}

export function boneLookAt(bone, from, to) {
    from = new Three.Vector3(
        bone.matrixWorld.elements[12],
        bone.matrixWorld.elements[13],
        bone.matrixWorld.elements[14]
    ).normalize();
    var target = new Three.Vector3(
        to.x - bone.matrixWorld.elements[12],
        to.y - bone.matrixWorld.elements[13],
        to.z - bone.matrixWorld.elements[14]
    ).normalize();
    var q = new Three.Quaternion().setFromUnitVectors(from, target);
    var tmp = q.z;
    q.z = -q.y;
    q.y = tmp;
    bone.quaternion.copy(q);
}

export function setVrmBoneRotation(vrm, boneType, rotation) {
    const bone = vrm.humanoid?.getBoneNode(boneType)
    if (bone != null) {
        bone.rotation.set(rotation.x, rotation.y, rotation.z)
        console.log("setVrmBoneRotation: " + bone.rotation)
    }
}

export function rotateBone(bone, rotation) {
    if (bone != null) {
        bone.rotation.x += rotation.x
        bone.rotation.y += rotation.y
        bone.rotation.z += rotation.z
    }
}

export function findBone(object, name = "") {
    var result = null
    if (object.isBone === true && object.name.toLowerCase().indexOf(name.toLowerCase()) !== -1) {
        //        console.log("found bone: " + object.name);
        result = object;
    }

    for (let i = 0; i < object.children.length && result == null; i++) {
        result = findBone(object.children[i], name);
    }

    return result;
}


export function getBoneList(object, index = 0) {
    const boneList = [];

    if (object.isBone === true) {
//        console.log(index + " bone: " + object.name)
        boneList.push(object);
    }

    for (let i = 0; i < object.children.length; i++) {
        boneList.push.apply(boneList, getBoneList(object.children[i], index + 1));
    }

    return boneList;
}

export function indexOfSkeleteBone(nodes, boneName) {
    console.log("try to find: " + boneName);

    for (let e = 0; e < nodes.length; e++) {
        const bone = nodes[e]
        if (bone.name?.toLowerCase() == boneName.toLowerCase()) {
            console.log("found: " + bone.node + "  bone: " + JSON.stringifybone);

            return e
        }
    }

    return null
}


export function fixBonesNames(scene, nodes, vrmBones) {
    const bones = getBoneList(scene)
    for (let i = 0; i < vrmBones.length; i++) {
        const vrmBone = vrmBones[i];
        const node = nodes[vrmBone.node]
        for (let e = 0; e < bones.length; e++) {
            const bone = bones[e]
            if (bone.name == node.name) {
                console.log("fixBonesNames found " + bone.name + " renamed to: " + vrmBone.bone);
                bone.name = vrmBone.bone
                bone.userData.name = vrmBone.bone
                break;
            }
        }
    }
    console.log("fixBonesNames bones: " + JSON.stringify(bones));
}

export function findSkeleteBones(nodes, names = ["hips", "leftUpperLeg", "rightUpperLeg", "leftLowerLeg", "rightLowerLeg", "leftFoot", "rightFoot", "spine", "chest", "neck", "head", "leftShoulder", "rightShoulder", "leftUpperArm", "rightUpperArm", "leftLowerArm", "rightLowerArm", "leftHand", "rightHand", "leftToes", "rightToes", "leftEye", "rightEye", "leftThumbProximal", "leftThumbIntermediate", "leftThumbDistal", "leftIndexProximal", "leftIndexIntermediate", "leftIndexDistal", "leftMiddleIntermediate", "leftMiddleDistal", "leftRingProximal", "leftRingIntermediate", "leftRingDistal", "leftLittleProximal", "leftLittleIntermediate", "leftLittleDistal", "rightThumbProximal", "rightThumbIntermediate", "rightThumbDistal", "rightIndexProximal", "rightIndexIntermediate", "rightIndexDistal", "rightMiddleProximal", "rightMiddleIntermediate", "rightMiddleDistal", "rightRingProximal", "rightRingIntermediate", "rightRingDistal", "rightLittleProximal", "rightLittleIntermediate", "rightLittleDistal", "upperChest"]) {
    var result = []
    for (let i = 0; i < names.length; i++) {
        const boneName = names[i];

        var itemIndex = indexOfSkeleteBone(nodes, boneName)

        if (itemIndex != null) {
            console.log("boneName " + boneName + " bone.node: " + itemIndex);
            result.push(
                {
                    "bone": boneName,
                    "node": itemIndex,
                    "useDefaultValues": true
                    // ,"min": {
                    //     "x": 0,
                    //     "y": 0,
                    //     "z": 0
                    // },
                    // "max": {
                    //     "x": 0,
                    //     "y": 0,
                    //     "z": 0
                    // },
                    // "center": {
                    //     "x": 0,
                    //     "y": 0,
                    //     "z": 0
                    // },
                    // "axisLength": 0
                }
            )

        } else {
            console.log("findSkeleteBones.boneName " + boneName + " is not found!!!");
        }
    }

    return result;
}



export function getNodesList(object, index = 0) {
    const boneList = [];

    console.log(index + " mesh: " + object.name)
    boneList.push(object);

    for (let i = 0; i < object.children.length; i++) {
        boneList.push.apply(boneList, getNodesList(object.children[i], index + 1));
    }

    return boneList;
}

export function findBonesPair(bones) {
    var leftBone = null
    var rightBone = null
    var distance = 0

    for (let i = 0; i < bones.length; i++) {
        const bone = bones[i]
        const boneName = bone.name.toLowerCase()
        const boneName2 = bone.userData?.name?.toLowerCase()
        //        console.log("findBonesPair, boneName: " + boneName + "  boneName2: " + boneName2)
        if (bone.isBone === true) {
            var rightBoneName = null
            if (boneName.indexOf("left") !== -1 || boneName.indexOf(".l") !== -1 || boneName.indexOf("_l") !== -1) {
                rightBoneName = boneName.replace("left", "right").replace(".l", ".r").replace("_l", "_r")
            }
            if (boneName2?.indexOf("left") >= 0 || boneName2?.indexOf(".l") >= 0 - 1) {
                rightBoneName = boneName2.replace("left", "right").replace(".l", ".r").replace("_l", "_r")
            }
            if (rightBoneName != null) {
                //                console.log("left: " + bone.name + " to rightBoneName: " + rightBoneName)
                for (let e = 0; e < bones.length; e++) {
                    const bone2 = bones[e]
                    const boneNameR = bone2.name.toLowerCase()
                    const boneNameR2 = bone2.userData?.name?.toLowerCase()
                    if (bone2.isBone === true && (boneNameR == rightBoneName || boneNameR2 == rightBoneName)) {
                        const bonesDistance = bone.localToWorld(new Three.Vector3(0, 0, 0)).distanceTo(bone2.localToWorld(new Three.Vector3(0, 0, 0)))
                        //                    console.log(">> " + boneName + " to " + boneNameR + " = " + bonesDistance)
                        if (bonesDistance > distance) {
                            leftBone = bone
                            rightBone = bone2
                            distance = bonesDistance
                        }
                    }
                }
            }
        }
    }
    console.log("left: " + leftBone?.name + "  right: " + rightBone?.name + "  distance: " + distance)
    return [leftBone, rightBone]
}

export function angle2points(originX, originY, targetX, targetY) {
    var dx = originX - targetX;
    var dy = originY - targetY;

    var theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
    theta *= 180 / Math.PI;           // [0, 180] then [-180, 0]; clockwise; 0° = east
    if (theta < 0) theta += 360;      // [0, 360]; clockwise; 0° = east

    return theta;
}

export function applyMatrix(model) {
    model.updateMatrixWorld();
    model.updateMatrix();
    //    updateMatrix(model, model.matrix)

    model.matrix.identity();
}

export function updateMatrix(model, matrix) {
    for (let i = 0, l = model.children.length; i < l; i++) {
        const object = model.children[i];
        console.log("object typeof: " + typeof object)
        console.log("object keys: " + Object.keys(object))
        if (object.geometry != null) {
            object.geometry.applyMatrix4(model.matrix);
        } else {
            updateMatrix(object, matrix)
        }
    }
}

export function fixModelRotation(model) {
    var result = false
    const bones = getBoneList(model)
    console.log("getBoneList: " + JSON.stringify(bones))
    const [leftBone, rightBone] = findBonesPair(bones)
    console.log("leftBone: " + leftBone + "  rightBone: " + rightBone)
    if (leftBone != null && rightBone != null) {
        const leftPoint = leftBone.localToWorld(new Three.Vector3(0, 0, 0))
        const rightPoint = rightBone.localToWorld(new Three.Vector3(0, 0, 0))
        const angle = angle2points(rightPoint.x, rightPoint.z, leftPoint.x, leftPoint.z)
        console.log(JSON.stringify(leftPoint) + "   " + JSON.stringify(rightPoint) + ", angle: " + angle)
        model.rotation.y += Three.MathUtils.degToRad(angle) + Math.PI

        result = true
    }
    return result
}
export function fixModelScale(model, minHeight = 2.0, maxHeight = 2.5, minThickness = 1, maxThickness = 3) {
    const aabb = new Three.Box3().setFromObject(model);
    const bounds = aabb.getSize(new Three.Vector3());
    console.log("aabb: " + JSON.stringify(aabb))
    console.log("bounds: " + JSON.stringify(bounds))

    var minThick = Math.min(bounds.x, bounds.z)
    var maxThick = Math.max(bounds.x, bounds.z)

    var k = 1.0

    if (minThick < minThickness) {
        k = minThickness / minThick
    } else if (maxThick > maxThickness) {
        k = maxThickness / maxThick
    }

    bounds.multiplyScalar(k)

    if (bounds.y < minHeight) {
        k *= minHeight / bounds.y
    } else if (bounds.y > maxHeight) {
        k *= maxHeight / bounds.y
    }

    console.log("k: " + k + " scale: " + JSON.stringify(model.scale))
    model.scale.multiplyScalar(k, k, k)
    //    model.matrix.scale(new Three.Vector3(k, k, k))
}


export function walk(root, index = 0, stack = []) {
    for (let property in root) {
        var text = ""
        for (let i = 0; i < index; i++) {
            text += "  "
        }
        const fingerprint = Object.keys(root[property] || []).join(",")

        if (stack.indexOf(fingerprint) == -1) {
            console.log(text + property + ": " + typeof root[property]);
            stack.push(fingerprint)
            if (root[property] instanceof Array) {

                if (root[property].size > 0) {
                    const item = root[property][0]
                    stack = walk(item, index + 1, stack);
                }
            }
            else if (root[property] instanceof Object) {
                stack = walk(root[property], index + 1, stack);
            }
        } else {
            var logtext = text + property + ": " + typeof root[property]
            if (root[property] instanceof Object && fingerprint.length > 0) {
                logtext += " [" + fingerprint + "]"
            }
            console.log(logtext);
        }
    }
    return stack
}
