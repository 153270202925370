import * as Three from 'three'
import * as ModelTools from './ModelTools.js'

export default class GLTFCustomExporterPlugin {
    constructor(writer, original) {
        this.writer = writer;
        this.name = 'VRM';
        this.vrm = original.extensions?.VRM || [];
        this.source = original;
//        console.log("GLTFCustomExporterPlugin, vrm:", JSON.stringify(this.vrm))
        console.log("GLTFCustomExporterPlugin, original:", Object.keys(original))
    }

    afterParse(input) {
        const root = this.writer.json;
//        console.log(JSON.stringify(input))
        console.log("nodes: " + JSON.stringify(root.nodes))
        console.log("this.humanBones: " + JSON.stringify(this.vrm?.humanoid?.humanBones))
        console.log("this.source.nodes: " + JSON.stringify(this.source?.nodes))
        const humanBones = ModelTools.findSkeleteBones(root.nodes);
        console.log("result.humanBones: " + JSON.stringify(humanBones))
        const firstPersonBone = ModelTools.indexOfSkeleteBone(root.nodes, "head")
        console.log("firstPersonBone: " + firstPersonBone)
        const humanoid = {
            "humanBones": humanBones,
            "armStretch": 0.05,
            "legStretch": 0.05,
            "upperArmTwist": 0.5,
            "lowerArmTwist": 0.5,
            "upperLegTwist": 0.5,
            "lowerLegTwist": 0.5,
            "feetSpacing": 0,
            "hasTranslationDoF": false
        }

        // this.vrm2 = {
        //     "exporterVersion": "Web-Based Avatar Tools 0.01",
        //     "specVersion": "0.0",
        //     "meta": {
        //         "title": "Alicia Solid",
        //         "version": "1.10",
        //         "author": "© DWANGO Co., Ltd.",
        //         "contactInformation": "https:\/\/3d.nicovideo.jp\/alicia\/",
        //         "reference": "",
        //         "texture": 7,
        //         "allowedUserName": "Everyone",
        //         "violentUssageName": "Disallow",
        //         "sexualUssageName": "Disallow",
        //         "commercialUssageName": "Allow",
        //         "otherPermissionUrl": "https:\/\/3d.nicovideo.jp\/alicia\/rule.html",
        //         "licenseName": "Other",
        //         "otherLicenseUrl": "https:\/\/3d.nicovideo.jp\/alicia\/rule.html"
        //     },
        //     "humanoid": humanoid
        // }
        this.vrm.humanoid = humanoid
        this.vrm.exporterVersion = "Web-Based Avatar Tools 0.01"
        this.vrm.specVersion = "0.0",

        this.vrm.meta = this.vrm.meta || {}
        this.vrm.meta.texture = undefined
        this.vrm.blendShapeMaster =  {
            "blendShapeGroups": [
                {
                    "name": "Neutral",
                    "presetName": "neutral",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "A",
                    "presetName": "a",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "I",
                    "presetName": "i",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "U",
                    "presetName": "u",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "E",
                    "presetName": "e",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "O",
                    "presetName": "o",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Blink",
                    "presetName": "blink",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Joy",
                    "presetName": "joy",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Angry",
                    "presetName": "angry",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Sorrow",
                    "presetName": "sorrow",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Fun",
                    "presetName": "fun",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "LookUp",
                    "presetName": "lookup",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "LookDown",
                    "presetName": "lookdown",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "LookLeft",
                    "presetName": "lookleft",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "LookRight",
                    "presetName": "lookright",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Blink_L",
                    "presetName": "blink_l",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                },
                {
                    "name": "Blink_R",
                    "presetName": "blink_r",
                    "binds": [],
                    "materialValues": [],
                    "isBinary": false
                }
            ]
        }
        this.vrm.secondaryAnimation = undefined
        this.vrm.materialProperties = undefined
        this.vrm.firstPerson = {
            "firstPersonBone": firstPersonBone,
            "firstPersonBoneOffset": {
                "x": 0,
                "y": 0.0599999428,
                "z": 0
            },
            "meshAnnotations": [
            ],
            "lookAtTypeName": "Bone",
            "lookAtHorizontalInner": {
                "curve": [
                    0,
                    0,
                    0,
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                "xRange": 20,
                "yRange": 5
            },
            "lookAtHorizontalOuter": {
                "curve": [
                    0,
                    0,
                    0,
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                "xRange": 20,
                "yRange": 5
            },
            "lookAtVerticalDown": {
                "curve": [
                    0,
                    0,
                    0,
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                "xRange": 20,
                "yRange": 5
            },
            "lookAtVerticalUp": {
                "curve": [
                    0,
                    0,
                    0,
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                "xRange": 20,
                "yRange": 5
            }
        }

        //console.log("vrm: " + JSON.stringify(this.vrm))
        root.extensions = root.extensions || {};
        root.extensions[this.name] = this.vrm;
        this.writer.extensionsUsed[this.name] = true;
    }
    // writeTexture(texture, textureDef) {
    //     const writer = this.writer;
    //     const extensionDef = {};

    //     textureDef.extensions = textureDef.extensions || {};
    // }
    // writeMaterial(material, materialDef) {
    //     const writer = this.writer;
    //     const extensionDef = {};

    //     materialDef.extensions = materialDef.extensions || {};
    // }

    // writeNode(node, nodeDef) {
    //     console.log("Test: " + node)
    //     // if (node.isInstancedMesh !== true) {
    //     //     return;
    //     // }

    //     const writer = this.writer;
    //     const extensionDef = {};

    //     nodeDef.extensions = nodeDef.extensions || {};
    //     nodeDef.extensions[this.name] = extensionDef;
    //     writer.extensionsUsed[this.name] = true;
    // }
}