<template>
  <div id="app">
    <div class="main">
      <h1>Web-Based Avatar Tools</h1>
      <label>Import vrm, glb/gltf or fbx file</label>
      <br />
      <File-Loader ref="fileLoader" @onFileReceived="onFileReceived" v-if="isFileLoaderVisible" />
      <span class="frame">
        <input type="checkbox" id="checkbox_debug" v-model="isDebugEnabled" />
        <label for="checkbox_debug">Show Debug</label>
      </span>
      <span class="frame">
        <input type="checkbox" id="checkbox_autorotate" v-model="isAutoRotateEnabled" />
        <label for="checkbox_autorotate">Auto Rotate</label>
      </span>
      <span class="frame">
        <input type="checkbox" id="checkbox_vrm_supported" v-model="isVRMEnabled" />
        <label for="checkbox_vrm_supported">VRM Supported</label>
      </span>

      <button v-if="isExportButtonVisible" v-on:click="onFixModelRotationClicked">Fix Rotation</button>
      <button v-if="isExportButtonVisible" v-on:click="onFixModelScaleClicked">Fix Scale</button>
      <Model-Viewer ref="modelViewer" v-if="isModelViewerVisible" @onModelAvailable="onModelAvailable" />
      <div>{{ modelInformation }}</div>
      <button class="fullbutton" v-if="isExportButtonVisible" v-on:click="onExportClicked">EXPORT</button>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line 
import FileLoader from './components/FileLoader.vue';
// eslint-disable-next-line 
import ModelViewer from './components/ModelViewer.vue';
import GLTFCustomExporterPlugin from './tools/GLTFCustomExporterPlugin.js';

import { saveAs } from "file-saver";
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

export default {
  name: "App",
  data() {
    return {
      isFileLoaderVisible: true,
      isModelViewerVisible: true,
      isExportButtonVisible: false,
      isDebugEnabled: false,
      isAutoRotateEnabled: false,
      isVRMEnabled: false,
      modelInformation: ""
    };
  },
  methods: {
    onFileReceived(file, fileName) {
      this.isModelViewerVisible = true
      console.log("onFileReceived:", file, fileName)
      this.$refs.modelViewer.onFileReceived(file, fileName)
    },
    onFixModelRotationClicked(event) {
      this.$refs.modelViewer.fixModelRotation()
    },
    onFixModelScaleClicked(event) {
      this.$refs.modelViewer.fixModelScale()
    },
    onExportClicked(event) {
      console.log("onExportClicked")
      const self = this
      const gltf = this.$refs.modelViewer.currentModel
      const scene = gltf.scene
      console.log("export gltf: " + Object.keys(gltf.parser.json))
      console.log("extensionsUsed: " + gltf.parser.json.extensionsUsed)
      // console.log("export scene.extensions: " + Object.keys(scene.extensions))
      // console.log("export extensions: " + JSON.stringify(scene.extensions.VRM))
      // console.log("export scene.userData: " + JSON.stringify(scene.userData))
      // console.log("export extensions: " + JSON.stringify(scene.extensions.VRM))

      const exporter = new GLTFExporter();
      if (this.isVRMEnabled) {
        exporter.register((writer) => {
          return new GLTFCustomExporterPlugin(writer, gltf.parser.json);
        });
      }
      exporter.parse(
        scene,
        function (gltfModel) {
          const blob = new Blob([gltfModel], { type: "application/octet-stream" });
          var name = "exported.glb"
          if (self.isVRMEnabled) {
            name = "exported.vrm"
          }
          saveAs(blob, name);
          console.log("Download requested");
        },
        function (error) {
          console.log('An error happened', error);
        },
        {
          binary: true,
          trs: true,
          maxTextureSize: 2048,
          forceIndices: true,
          includeCustomExtensions: true
        }
      );
    },
    onModelAvailable(scene) {
      this.isExportButtonVisible = true
      console.log("onModelAvailable:", scene)
    },
  },
  watch: {
    isDebugEnabled: function (value) {
      this.$refs.modelViewer.isDebugEnabled = value
    },
    isAutoRotateEnabled: function (value) {
      this.$refs.modelViewer.isAutoRotateEnabled = value
    },
    isVRMEnabled: function (value) {
      this.$refs.modelViewer.isVRMEnabled = value
    }
  },
  // eslint-disable-next-line 
  components: { FileLoader, ModelViewer }
}
</script>

<style>
.main {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  width: 640px;
  margin: 20px auto;
  border-radius: 2px;
  padding: 40px 50px;
  box-shadow: 0 0 1px;
}

.hide {
  display: none;
}

.frame {
  border: 1px solid #999;
  border-radius: 3px;
  margin: 10px 10px;
  padding: 5px 5px;
}

.row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.column {
  display: table-cell;
  border-radius: 1px;
  box-shadow: 0 0 1px;
  align-content: center;
  text-align: center;
}

.cloudButton {
  width: 80%;
  height: 128px;
  padding: 10px;
}

h1,
h2 {
  font-weight: normal;
}

a {
  color: #42b983;
  width: 400px;
}

button {
  height: 40px;
  background-color: #123456;
  border-radius: 5px;
  margin: 10px auto 10px;
  border: 1px solid white;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-align: center;
}

.fullbutton {
  width: 100%;
  height: 40px;
  background-color: #123456;
  border-radius: 5px;
  margin: 10px auto 10px;
  border: 1px solid white;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-align: center;
}
</style>