<template>
  <div class="row">
    <div v-if="!isInProgress">
      <div class="column">
        <img class="cloudButton" src="./../assets/localfile.svg" @click="onOpenFileClicked">
        <input type="file" ref="selectedFile" class="hide" accept=".gltf,.glb,.vrm,.fbx" @change="onFileSelected"
          single>
      </div>
      <div class="column">
        <img class="cloudButton" src="./../assets/dropbox.svg" @click="onDropBoxClicked" />
      </div>
      <div class="column">
        <img class="cloudButton" src="./../assets/onedrive.svg" @click="onOneDriveClicked" />
      </div>
      <div class="column">
        <img class="cloudButton" src="./../assets/googledrive.svg" @click="onGoogleDriveClicked" />
      </div>
    </div>
    <div class="h-screen w-screen flex items-center justify-center" v-if="isInProgress">Loading...</div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: "FileLoader",
  data() {
    return {
      isInProgress: false
    }
  },
  mounted() {
    if (!document.getElementById('dropboxjs')) {
      let dropBox = document.createElement("script");
      dropBox.setAttribute(
        "src",
        "https://www.dropbox.com/static/api/2/dropins.js"
      );
      dropBox.setAttribute("id", "dropboxjs");
      dropBox.setAttribute("data-app-key", "ivqdqnrdllc4sa2");
      document.head.appendChild(dropBox);
    }
  },
  methods: {
    onOpenFileClicked() {
      console.log("clicked");
      this.$refs.selectedFile.click();
    },
    onOneDriveClicked() {
      alert("OneDrive is temporarily not supported")
    },
    onGoogleDriveClicked() {
      alert("GoogleDrive is temporarily not supported")
    },
    onDropBoxClicked() {
      let self = this
      let options = {
        success: async function (files) {
          if (files.length >= 1) {
            self.isInProgress = true
            console.log(files[0].name, files[0].link);
            axios
              .get(files[0].link, { responseType: 'arraybuffer' })
              .then(function (result) {
                self.isInProgress = false
                self.$emit('onFileReceived', result.data, files[0].name)
              })
              .catch(function () {
                self.isInProgress = false
              });
          }
        },

        cancel: function () { },

        linkType: "direct",
        multiselect: false,
        folderselect: false,

        extensions: [
          ".gltf",
          ".glb",
          ".vrm",
          ".fbx",
        ],

        sizeLimit: 102400000
      };
      console.log(options)
      // eslint-disable-next-line 
      Dropbox.choose(options);
    },
    onFileSelected(event) {
      var self = this
      var files = event.target.files
      console.log(files);
      if (files.length > 0) {
        console.log(files[0]);
        self.isInProgress = true

        const reader = new FileReader();
        reader.onload = (res) => {
          self.isInProgress = false
          self.$emit('onFileReceived', res.target.result, files[0].name)
        };
        reader.onerror = (err) => {
          console.log(err);
          self.isInProgress = false
        }
        reader.readAsArrayBuffer(files[0]);

      }
    }
  }
}
</script>


<style scoped>
.hide {
  display: none;
}

.row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.column {
  display: table-cell;
  border-radius: 1px;
  box-shadow: 0 0 1px;
  align-content: center;
  text-align: center;
}

.cloudButton {
  width: 80%;
  height: 128px;
  padding: 10px;
}
</style>